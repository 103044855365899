import "../styles/globals.css";
import { AppProps } from "next/app";
import { PrismicProvider, PrismicToolbar } from "@prismicio/react";
import { client, repositoryName } from "../prismicio";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <PrismicProvider client={client}>
        <PrismicToolbar repositoryName={repositoryName} />
        <Component {...pageProps} />
      </PrismicProvider>
    </>
  );
}

export default MyApp;
